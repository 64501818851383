@import 'src/styles/variables';
@import 'src/styles/mixins';

.pointsContainer {
  @include verticalGap(16px);
  padding: 16px;
  border: 1px solid $black10;
  border-radius: 12px;
  box-shadow: $deepBoxShadow;
  align-items: center;

  .pointsTitle {
    font-size: 16px;
    font-weight: $boldFontWeight;
    line-height: 28px;
  }

  .pointsRowContainer {
    @include verticalGap(12px);
    flex-direction: row;
  }

  .pointsRow {
    @include verticalGap(12px);
    @include paragraph;
    width: 100%;
    text-align: center;
    color: $black80;

    p span {
      color: $purple500;
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }

  .pointsRow div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;

    @include body-text(lg, 800);
    line-height: 16px;
    background: linear-gradient(180deg, $purple500 0%, #3e20b9 100%);

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    span {
      font-size: 40px;
      line-height: 32px;
      padding-right: 3px;
    }
  }

  .pointsListContainer {
    @include verticalGap(12px);
    width: 100%;

    .pointListCard {
      @include paragraph;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
    }

    .pointListCardIcon {
      width: 40px;
      height: 40px;
    }

    .pointListTile {
      font-weight: $boldFontWeight;
    }
  }
}

.rewardsDisclaimer {
  @include disclaimerContainer;
}
