@import 'src/styles/mixins';
@import 'src/styles/variables';

.mainContainer {
  @include verticalGap(24px);
  @include body-text('lg');

  color: $black80;

  .title {
    font-weight: $boldFontWeight;
    color: $black100;
  }

  .listItemsContainer {
    padding-left: 8px;

    .listItem {
      @include verticalGap(4px);

      flex-direction: row;

      span {
        width: 8px;
        padding-right: 5px;
      }
    }
  }
}
